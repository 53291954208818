
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.Container_659cafec1b71c003{display:inline-block;line-height:1;width:var(--size_b7481f411d2d587b);height:var(--size_b7481f411d2d587b);-webkit-animation:legos-progress-circular-rotate_172141375997ec28 1.4s linear infinite;animation:legos-progress-circular-rotate_172141375997ec28 1.4s linear infinite}.Circle_7a9e4a417ae80d19{stroke:var(--color_ae4bf2c9cf964e79);stroke-linecap:butt;stroke-dasharray:80px,200px;stroke-dashoffset:0;-webkit-animation:legos-progress-circular-dash_4a6f07e24a9ad574 1.4s ease-in-out infinite;animation:legos-progress-circular-dash_4a6f07e24a9ad574 1.4s ease-in-out infinite}@-webkit-keyframes legos-progress-circular-rotate_172141375997ec28{to{transform:rotate(1turn)}}@keyframes legos-progress-circular-rotate_172141375997ec28{to{transform:rotate(1turn)}}@-webkit-keyframes legos-progress-circular-dash_4a6f07e24a9ad574{0%{stroke-dasharray:1px,200px;stroke-dashoffset:0px}50%{stroke-dasharray:100px,200px;stroke-dashoffset:-15px}to{stroke-dasharray:100px,200px;stroke-dashoffset:-120px}}@keyframes legos-progress-circular-dash_4a6f07e24a9ad574{0%{stroke-dasharray:1px,200px;stroke-dashoffset:0px}50%{stroke-dasharray:100px,200px;stroke-dashoffset:-15px}to{stroke-dasharray:100px,200px;stroke-dashoffset:-120px}}`;
styleInject(_css)

/** Container Props */
export type ContainerCCM = {
  /** Container Component Custom Properties */
  '$size': string;

  /** Container Modifier Flags */
  // No modifiers classes found
};
/** Base Container component */
export const Container: ComponentCreator<ContainerCCM> = createComponentCreator({
  "name": "Container",
  "base": "Container_659cafec1b71c003",
  "prop": {
    "$size": "--size_b7481f411d2d587b"
  },
  "mod": {}
});


/** Circle Props */
export type CircleCCM = {
  /** Circle Component Custom Properties */
  '$color': string;

  /** Circle Modifier Flags */
  // No modifiers classes found
};
/** Base Circle component */
export const Circle: ComponentCreator<CircleCCM> = createComponentCreator({
  "name": "Circle",
  "base": "Circle_7a9e4a417ae80d19",
  "prop": {
    "$color": "--color_ae4bf2c9cf964e79"
  },
  "mod": {}
});

