import { createElement, FC } from 'react';
import {
  CircularProgressTheme,
  useCircularProgressTheme
} from './circular-progress.theme';
import { Circle, Container } from './circular-progress.ccm.css';

const circularProgressSizes = {
  small: [20, 10, 2],
  normal: [48, 24, 4]
};

export interface CircularProgressProps {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default default
   */
  color?: keyof CircularProgressTheme;
  /**
   * The size of the circle.
   * @default normal
   */
  size?: keyof typeof circularProgressSizes;
}

/**
 * Use the `CircularProgress` to show an indeterminate spinner
 * for use in loading states.
 */
export const CircularProgress: FC<CircularProgressProps> = ({
  color = 'default',
  size = 'normal'
}) => {
  const theme = useCircularProgressTheme();
  const [diameter, radius, thickness] = circularProgressSizes[size];
  const colorCode = theme[color];

  return (
    <Container.div role="progressbar" $size={`${diameter}px`}>
      <svg viewBox={`${radius} ${radius} ${diameter} ${diameter}`}>
        <Circle.circle
          cx={diameter}
          cy={diameter}
          r={(diameter - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          $color={colorCode}
        />
      </svg>
    </Container.div>
  );
};
