import { createUseThemeHook, LegoTheme } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    circularProgress: CircularProgressTheme;
  }
}

export interface CircularProgressTheme {
  default: string;
  accent: string;
  positive: string;
  white: string;
}

const [
  CircularProgressThemeProvider,
  useCircularProgressTheme
] = createUseThemeHook(
  'circularProgress',
  (global: LegoTheme): CircularProgressTheme => ({
    default: global.default.color500,
    accent: global.accent.color500,
    positive: global.positive.color500,
    white: '#FFFFFF'
  })
);

export { CircularProgressThemeProvider, useCircularProgressTheme };
